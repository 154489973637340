import { Link, useLocation } from 'react-router-dom';

import { Button } from '@common/Button/Button';
import { Card } from '@common/Card/Card';
import { IconArrow } from '@common/Icons/Icons';

export default function NotFound() {
  const { pathname } = useLocation();

  return (
    <Card className="p-24" style={{ maxWidth: 560, margin: '0 auto' }}>
      <h6 className="mb-8 color-secondary-60">404</h6>
      <h3>Page Not found</h3>
      <p>
        No page found for <code>{pathname}</code>.
      </p>
      <Button as={Link} to="/" className="d-inline-block">
        Take me home
        <IconArrow direction="right" className="ml-4 mr-n4" />
      </Button>
    </Card>
  );
}
