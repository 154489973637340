export const appRoutes = (teamUuid, appUuid) =>
  [
    { route: `${teamUuid}/${appUuid}`, title: 'Overview', end: true },
    {
      route: `${teamUuid}/${appUuid}/relays`,
      title: 'Relays',
      end: false,
    },
    {
      route: `${teamUuid}/${appUuid}/functions`,
      title: 'Functions',
      end: false,
    },
    {
      route: `${teamUuid}/${appUuid}/enclaves`,
      title: 'Enclaves',
      end: false,
    },
    {
      route: `${teamUuid}/${appUuid}/logs`,
      title: 'Logs',
      end: false,
    },
    {
      route: `${teamUuid}/${appUuid}/policies`,
      title: 'Data Policies',
      requiredFlag: 'dataPolicies',
    },
    {
      route: `${teamUuid}/${appUuid}/workflows`,
      title: 'Workflows',
      requiredFlag: 'enableWorkflows',
    },
    {
      route: `${teamUuid}/${appUuid}/compliance`,
      title: 'Compliance',
      requiredFlag: 'complianceTab',
    },
    {
      route: `${teamUuid}/${appUuid}/settings`,
      title: 'Settings',
      end: false,
    },
  ].filter(Boolean);
