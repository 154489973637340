import { useDialogContext } from '@common/Dialog';
import Switch from '@common/Switch/Switch';
import { FormInput } from '@common/FormInput/FormInput';
import { Restricted } from '@components/Restricted';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDialog } from '@common/Dialog';
import { UpgradeModal } from '@common/UpgradeButton/UpgradeModal';
import {
  selectAppNames,
  useAppsQuery,
  useCreateAppMutation,
} from '@queries/app';
import { DEV_OR_ADMIN } from '@utils/roles';
import { Flex, Text } from '@vault';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { z } from 'zod';
import { useCurrentTeam } from '@queries/user';

const buildEditAppSchema = (appNames) =>
  z.object({
    name: z
      .string()
      .min(2, { message: 'App name must be at least 2 characters long' })
      .max(30, { message: "App name can't be longer than 30 characters" })
      .regex(/^[a-zA-Z0-9\s\-_']+$/i, {
        message:
          "App name can only contain alphanumeric characters, spaces, and ',.-",
      })
      .refine((value) => !appNames.includes(value?.toUpperCase()), {
        message: 'Looks like you already have an App with that name',
      }),
  });

export default function CreateApp({ teamUuid, onCreate = (_) => {} }) {
  const dialog = useDialogContext();
  const upgradeDialog = useDialog();
  const { mutateAsync: createAppAsync } = useCreateAppMutation(teamUuid);
  const { data: appNames } = useAppsQuery(teamUuid, {
    select: selectAppNames,
  });

  const { team } = useCurrentTeam();
  const apps = team?.apps || [];

  const hasProdApp = apps.some((app) => !app.sandbox);
  const hasSandboxApp = apps.some((app) => app.sandbox);
  const reachedAppLimit = team?.tier === 'free' && hasProdApp && hasSandboxApp;

  const {
    control,
    register,
    handleSubmit,
    formState: { isSubmitting, errors, isValid },
  } = useForm({
    defaultValues: {
      name: '',
      sandbox: false,
    },
    resolver: zodResolver(buildEditAppSchema(appNames ?? [])),
  });

  const watchedSandbox = useWatch({
    control,
    name: 'sandbox',
    defaultValue: false,
  });

  const canCreateApp = () => {
    if (team?.tier !== 'free') return true;

    if (reachedAppLimit) {
      return false;
    }

    if (watchedSandbox && !hasSandboxApp) {
      return true;
    }

    return !watchedSandbox && !hasProdApp;
  };

  const submissionHandler = async (values) => {
    try {
      if (!canCreateApp()) {
        upgradeDialog.open();
      } else {
        const response = await createAppAsync({
          appName: values.name,
          sandbox: watchedSandbox,
        });
        toast.success('App created successfully.');
        dialog?.close();
        onCreate(response.app);
      }
    } catch (e) {
      console.error(e);
      toast.error('An error occurred while creating your App.');
    }
  };

  return (
    <>
      <UpgradeModal
        state={upgradeDialog}
        teamUuid={teamUuid}
        subText="Upgrade to Pro to create unlimited Apps with full functionality."
      />
      <form onSubmit={handleSubmit(submissionHandler)}>
        <FormInput
          label="App name"
          name="app-name"
          error={errors?.name}
          className="mb-12"
          inputProps={{
            autoFocus: true,
            placeholder: 'e.g. Staging',
            disabled: isSubmitting,
            ...register('name'),
            autoComplete: 'off',
            'data-testid': 'team-form-name',
          }}
        />
        <Flex align="center" className="mb-20">
          <Flex.Item shrink>
            <Text weight={500} leading="md">
              Sandbox Mode
            </Text>
            <Text color="var(--grey-60)" size="sm" leading="md">
              Sandbox apps allow you to evaluate Evervault enterprise features
              and test Evervault Primitives, whilst providing clear separation
              of production and non production data.
            </Text>
          </Flex.Item>
          <Controller
            control={control}
            name="sandbox"
            type="Checkbox"
            render={({ field: { onChange, value } }) => (
              <Switch isOn={value} onClick={() => onChange(!value)} />
            )}
          />
        </Flex>
        <Flex
          gap="12px"
          align="center"
          className="mb-12"
          display="flex"
          justify="between"
        >
          <Flex.Item>
            <Restricted.Button
              team={teamUuid}
              roles={DEV_OR_ADMIN}
              data-testid="submit-button"
              loading={isSubmitting}
              disabled={!isValid}
            >
              Create App
            </Restricted.Button>
          </Flex.Item>
        </Flex>
      </form>
    </>
  );
}
