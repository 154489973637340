import { useParams } from 'react-router-dom';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { api, directApi, handleResponseOptions } from '@services/apiService';
import {
  functions as functionsResource,
  functionsGithubInstall as functionsGithubInstallResource,
  func as functionResource,
  funcDeployments as functionDeploymentsResource,
  funcDeploymentDownload as functionDeploymentDownloadResource,
  funcInboundWhitelist as funcInboundWhitelistResource,
  funcInboundWhitelistIp as funcInboundWhitelistIpResource,
  funcOutboundWhitelist as funcOutboundWhitelistResource,
  funcOutboundWhitelistDomain as funcOutboundWhitelistDomainResource,
  funcRepoBranches as funcRepoBranchesResource,
  funcRunToken as funcRunTokenResource,
} from '@utils/resources/functions';
import { appGithubRepoStatus as appGithubRepoStatusResource } from '@utils/resources/github';

export const functionKeys = {
  scope: (teamUuid, appUuid) => ['functions', teamUuid, appUuid],
  functions: (teamUuid, appUuid) => [...functionKeys.scope(teamUuid, appUuid)],
  func: (teamUuid, appUuid, functionUuid) => [
    ...functionKeys.functions(teamUuid, appUuid),
    'function',
    functionUuid,
  ],
  deployments: (teamUuid, appUuid, functionUuid) => [
    ...functionKeys.func(teamUuid, appUuid, functionUuid),
    'deployments',
  ],
  deploymentDownload: (teamUuid, appUuid, functionUuid, deploymentId) => [
    ...functionKeys.deployments(teamUuid, appUuid, functionUuid),
    'download',
    deploymentId,
  ],
  repoBranches: (teamUuid, appUuid, functionUuid, isGithubFunction = false) => [
    ...functionKeys.func(teamUuid, appUuid, functionUuid),
    'repoBranches',
    isGithubFunction,
  ],
  repoStatus: (teamUuid, appUuid, functionUuid) => [
    ...functionKeys.func(teamUuid, appUuid, functionUuid),
    'repoStatus',
  ],
  notifications: (teamUuid, appUuid, functionUuid) => [
    ...functionKeys.func(teamUuid, appUuid, functionUuid),
    'notification',
  ],
  notification: (teamUuid, appUuid, functionUuid, configId) => [
    ...functionKeys.notifications(teamUuid, appUuid, functionUuid),
    configId,
  ],
};

const githubRepoStatus = (teamUuid, appUuid, token) => [
  'repoStatus',
  teamUuid,
  appUuid,
  token,
];

const useRefreshFunctions = () => {
  const { teamUuid, appUuid } = useParams();
  const queryClient = useQueryClient();

  return () =>
    queryClient.invalidateQueries({
      queryKey: functionKeys.functions(teamUuid, appUuid),
    });
};

export const useRefreshFunction = () => {
  const { teamUuid, appUuid } = useParams();
  const queryClient = useQueryClient();

  return (funcUuid) =>
    queryClient.invalidateQueries({
      queryKey: functionKeys.func(teamUuid, appUuid, funcUuid),
    });
};

export const functionsQuery = (teamUuid, appUuid) => ({
  queryKey: functionKeys.functions(teamUuid, appUuid),
  queryFn: async () =>
    (
      await api
        .get(
          functionsResource(teamUuid, appUuid),
          handleResponseOptions('functionsQuery')
        )
        .json()
    ).functions,
});

export function useFunctionsQuery(args = {}) {
  const { teamUuid, appUuid } = useParams();

  return useQuery({
    ...functionsQuery(teamUuid, appUuid),
    onError: (err) => console.error(err),
    ...args,
  });
}

const _isTerminalState = (status) =>
  ['cancelled', 'failed', 'deployed'].includes(status);

const functionQuery = (teamUuid, appUuid, functionUuid) => ({
  queryKey: functionKeys.func(teamUuid, appUuid, functionUuid),
  queryFn: async () =>
    api
      .get(
        functionResource(teamUuid, appUuid, functionUuid),
        handleResponseOptions('functionQuery')
      )
      .json(),
  enabled: functionUuid != null,
  refetchInterval: (functionData) =>
    !functionData?.latestDeployment?.deployedAt &&
    !_isTerminalState(functionData?.latestDeployment?.status)
      ? 2000
      : false,
});

export function useFunctionQuery(functionUuid, args = {}) {
  const { teamUuid, appUuid } = useParams();
  const queryClient = useQueryClient();

  return useQuery({
    ...functionQuery(teamUuid, appUuid, functionUuid),
    onSuccess({ latestDeployment }) {
      queryClient.setQueryData(
        functionKeys.deployments(functionUuid),
        (deploymentsData) => {
          if (!deploymentsData) return;

          return {
            ...deploymentsData,
            deployments: deploymentsData.deployments.map((deployment) => {
              if (deployment.id === latestDeployment?.id) {
                return { ...deployment, ...latestDeployment };
              }

              return deployment;
            }),
          };
        }
      );
    },
    ...args,
  });
}

const functionDeploymentsQuery = (teamUuid, appUuid, functionUuid) => ({
  queryKey: functionKeys.deployments(functionUuid),
  queryFn: async () =>
    api
      .get(functionDeploymentsResource(teamUuid, appUuid, functionUuid))
      .json(),
  select: (data) => data.deployments,
});

export function useFunctionDeploymentsQuery(
  functionUuid,
  { onError, onSuccess }
) {
  const { teamUuid, appUuid } = useParams();

  return useQuery({
    ...functionDeploymentsQuery(teamUuid, appUuid, functionUuid),
    onError,
    onSuccess,
  });
}

const repoBranchQuery = (
  teamUuid,
  appUuid,
  functionUuid,
  isGithubFunction = false,
  { select, onError, onSettled, onSuccess }
) => ({
  select,
  onError,
  onSuccess,
  onSettled,
  enabled: functionUuid != null && isGithubFunction,
  queryKey: functionKeys.repoBranches(
    teamUuid,
    appUuid,
    functionUuid,
    isGithubFunction
  ),
  queryFn: () =>
    directApi.get(
      funcRepoBranchesResource(teamUuid, appUuid, functionUuid),
      handleResponseOptions('repoBranchQuery')
    ),
});

export const useRepoBranchQuery = (
  functionUuid,
  isGithubFunction = false,
  options
) => {
  const { teamUuid, appUuid } = useParams();

  return useQuery(
    repoBranchQuery(teamUuid, appUuid, functionUuid, isGithubFunction, options)
  );
};

const repoStatusQuery = (teamUuid, appUuid, token, { select }) => ({
  select,
  enabled: token != null,
  queryKey: githubRepoStatus(teamUuid, appUuid, token),
  queryFn: () =>
    directApi
      .get(appGithubRepoStatusResource(teamUuid, appUuid), {
        ...handleResponseOptions('repoStatusQuery'),
        searchParams: new URLSearchParams().append('token', token),
      })
      .json(),
});

export const useRepoStatusQuery = (token, options) => {
  const { teamUuid, appUuid } = useParams();

  return useQuery(repoStatusQuery(teamUuid, appUuid, token, options));
};

const createFunctionViaGithubMutation = (teamUuid, appUuid) => ({
  mutationFn: async (funcData) =>
    api
      .post(functionsGithubInstallResource(teamUuid, appUuid), {
        ...handleResponseOptions('createFunctionViaGithubMutation'),
        json: funcData,
        // Creating functions can take a bit
        timeout: 30000,
      })
      .json(),
});

export const useCreateFunctionViaGithubMutation = () => {
  const { teamUuid, appUuid } = useParams();
  const refreshFunctions = useRefreshFunctions();

  return useMutation({
    ...createFunctionViaGithubMutation(teamUuid, appUuid),
    onSettled: async () => {
      await refreshFunctions();
    },
  });
};

const editFunctionMutation = (teamUuid, appUuid) => ({
  mutationFn: async ({ funcUuid, funcData }) =>
    api
      .put(functionResource(teamUuid, appUuid, funcUuid), {
        ...handleResponseOptions('editFunctionMutation'),
        json: funcData,
      })
      .json(),
});

export const useEditFunctionMutation = () => {
  const { teamUuid, appUuid } = useParams();
  const refreshFunctions = useRefreshFunctions();

  return useMutation({
    ...editFunctionMutation(teamUuid, appUuid),
    onSettled: async () => {
      await refreshFunctions();
    },
  });
};

const deleteFunctionMutation = (teamUuid, appUuid) => ({
  mutationFn: (funcUuid) =>
    api
      .delete(
        functionResource(teamUuid, appUuid, funcUuid),
        handleResponseOptions('deleteFunctionMutation')
      )
      .json(),
});

export const useDeleteFunctionMutation = () => {
  const { teamUuid, appUuid } = useParams();

  return useMutation(deleteFunctionMutation(teamUuid, appUuid));
};

const addIpsToFunctionInboundWhitelistMutation = (
  teamUuid,
  appUuid,
  functionUuid
) => ({
  mutationFn: async ({ addresses }) => {
    return api
      .post(funcInboundWhitelistResource(teamUuid, appUuid, functionUuid), {
        json: { addresses },
        ...handleResponseOptions('addIpsToFunctionInboundWhitelistMutation'),
      })
      .json();
  },
});

export const useAddIpsToFunctionInboundWhitelistMutation = (functionUuid) => {
  const refreshFunction = useRefreshFunction();
  const { teamUuid, appUuid } = useParams();

  return useMutation({
    ...addIpsToFunctionInboundWhitelistMutation(
      teamUuid,
      appUuid,
      functionUuid
    ),
    onSettled: async () => refreshFunction(functionUuid),
  });
};

const deleteIpFromInboundWhitelistMutation = (
  teamUuid,
  appUuid,
  functionUuid
) => ({
  mutationFn: async (ipId) => {
    return api
      .delete(
        funcInboundWhitelistIpResource(teamUuid, appUuid, functionUuid, ipId),
        handleResponseOptions('deleteIpFromInboundWhitelistMutation')
      )
      .json();
  },
});

export const useDeleteIpFromInboundWhitelistMutation = (functionUuid) => {
  const refreshFunction = useRefreshFunction();
  const { teamUuid, appUuid } = useParams();

  return useMutation({
    ...deleteIpFromInboundWhitelistMutation(teamUuid, appUuid, functionUuid),
    onSettled: async () => refreshFunction(functionUuid),
  });
};

const addDomainsToFunctionOutboundWhitelistMutation = (
  teamUuid,
  appUuid,
  functionUuid
) => ({
  mutationFn: async ({ domains }) => {
    return api
      .post(funcOutboundWhitelistResource(teamUuid, appUuid, functionUuid), {
        json: { domains },
        ...handleResponseOptions(
          'addDomainsToFunctionOutboundWhitelistMutation'
        ),
      })
      .json();
  },
});

export const useAddDomainsToFunctionOutboundWhitelistMutation = (
  functionUuid
) => {
  const refreshFunction = useRefreshFunction();
  const { teamUuid, appUuid } = useParams();

  return useMutation({
    ...addDomainsToFunctionOutboundWhitelistMutation(
      teamUuid,
      appUuid,
      functionUuid
    ),
    onSettled: async () => refreshFunction(functionUuid),
  });
};

const deleteDomainToFunctionOutboundWhitelistMutation = (
  teamUuid,
  appUuid,
  functionUuid
) => ({
  mutationFn: async (domainId) => {
    return api
      .delete(
        funcOutboundWhitelistDomainResource(
          teamUuid,
          appUuid,
          functionUuid,
          domainId
        ),
        handleResponseOptions('deleteDomainToFunctionOutboundWhitelistMutation')
      )
      .json();
  },
});

export const useDeleteDomainToFunctionOutboundWhitelistMutation = (
  functionUuid
) => {
  const refreshFunction = useRefreshFunction();
  const { teamUuid, appUuid } = useParams();

  return useMutation({
    ...deleteDomainToFunctionOutboundWhitelistMutation(
      teamUuid,
      appUuid,
      functionUuid
    ),
    onSettled: async () => refreshFunction(functionUuid),
  });
};

export const useDownloadDeployment = (deployment) => {
  const { teamUuid, appUuid } = useParams();
  const funcUuid = deployment.functionUuid;

  return useQuery({
    queryKey: functionKeys.deploymentDownload(
      teamUuid,
      appUuid,
      funcUuid,
      deployment.id
    ),
    queryFn: async () => {
      return api
        .get(
          functionDeploymentDownloadResource(
            teamUuid,
            appUuid,
            funcUuid,
            deployment.id
          )
        )
        .json();
    },
  });
};

const createRunTokenMutation = (teamUuid, appUuid, functionUuid) => ({
  mutationFn: async (payload) => {
    return api
      .post(funcRunTokenResource(teamUuid, appUuid, functionUuid), {
        json: payload,
      })
      .json();
  },
});

export const useCreateRunTokenMutation = (functionUuid) => {
  const { teamUuid, appUuid } = useParams();

  return useMutation({
    ...createRunTokenMutation(teamUuid, appUuid, functionUuid),
  });
};
