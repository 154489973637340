import { app } from './apps';

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const functions = (teamUuid, appUuid) =>
  `${app(teamUuid, appUuid)}/functions`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const functionsGithubInstall = (teamUuid, appUuid) =>
  `${functions(teamUuid, appUuid)}/github/install`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @returns {string}
 */
export const func = (teamUuid, appUuid, functionUuid) =>
  `${functions(teamUuid, appUuid)}/${functionUuid}`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @returns {string}
 */
export const funcDeployments = (teamUuid, appUuid, functionUuid) =>
  `${func(teamUuid, appUuid, functionUuid)}/deployments`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @param {string} deploymentId
 * @returns {string}
 */
export const funcDeploymentDownload = (
  teamUuid,
  appUuid,
  functionUuid,
  deploymentId
) =>
  `${funcDeployments(
    teamUuid,
    appUuid,
    functionUuid
  )}/${deploymentId}/download`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @returns {string}
 */
export const funcEnvironmentVariables = (teamUuid, appUuid, functionUuid) =>
  `${func(teamUuid, appUuid, functionUuid)}/environment`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @returns {string}
 */
export const funcInboundWhitelist = (teamUuid, appUuid, functionUuid) =>
  `${func(teamUuid, appUuid, functionUuid)}/inbound-whitelist`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @param {string} inboundIpId
 * @returns {string}
 */
export const funcInboundWhitelistIp = (
  teamUuid,
  appUuid,
  functionUuid,
  inboundIpId
) => `${funcInboundWhitelist(teamUuid, appUuid, functionUuid)}/${inboundIpId}`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @returns {string}
 */
export const funcOutboundWhitelist = (teamUuid, appUuid, functionUuid) =>
  `${func(teamUuid, appUuid, functionUuid)}/outbound-whitelist`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @param {string} outboundDomainId
 * @returns {string}
 */
export const funcOutboundWhitelistDomain = (
  teamUuid,
  appUuid,
  functionUuid,
  outboundDomainId
) =>
  `${funcOutboundWhitelist(
    teamUuid,
    appUuid,
    functionUuid
  )}/${outboundDomainId}`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @param {string} environmentVariableKey
 * @returns {string}
 */
export const funcEnvironmentVariable = (
  teamUuid,
  appUuid,
  functionUuid,
  environmentVariableKey
) =>
  `${funcEnvironmentVariables(
    teamUuid,
    appUuid,
    functionUuid
  )}/${environmentVariableKey}`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @returns {string}
 */
const funcRepo = (teamUuid, appUuid, functionUuid) =>
  `${func(teamUuid, appUuid, functionUuid)}/repo`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @returns {string}
 */
export const funcRepoBranches = (teamUuid, appUuid, functionUuid) =>
  `${funcRepo(teamUuid, appUuid, functionUuid)}/branches`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} functionUuid
 * @returns {string}
 */
export const funcRunToken = (teamUuid, appUuid, functionUuid) =>
  `${func(teamUuid, appUuid, functionUuid)}/run-token`;
