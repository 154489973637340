import * as Sentry from '@sentry/react';
import { ReactNode, useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Auth0Provider } from '@auth0/auth0-react';

import RootRouter from '@routes/RootRouter';

import ToastContainer from '@common/ToastContainer/ToastContainer';

import '@styles/custom-bootstrap.scss';
import '@styles/main.css';
import queryClient from './queryClient';
import { VaultProvider } from '@vault';
import { accessTokenStore } from '@state/authInfo';
import { useAuth0 } from '@hooks/useAuth0';

// We use Sentry for exception handling in the dashboard
Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 0.1,
  // only capture replay for errors.
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
});

function AppWithProviders() {
  return (
    <VaultProvider>
      <Auth0Provider
        useRefreshTokensFallback
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin + '/callback',
          audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        }}
      >
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Auth0Provider>
    </VaultProvider>
  );
}

function App() {
  const { getAccessTokenSilently, logout } = useAuth0();

  useEffect(() => {
    accessTokenStore.setGetTokenHandler(getAccessTokenSilently);
    accessTokenStore.setLogoutHandler(logout);
  }, [getAccessTokenSilently, logout]);

  return (
    <>
      <ToastContainer />
      <RootRouter />
      {process.env.NODE_ENV === 'development' &&
        window.localStorage.getItem('REACT_QUERY_DEVTOOLS') === 'true' && (
          <ReactQueryDevtools initialIsOpen={false} />
        )}
    </>
  );
}

export default Sentry.withProfiler(AppWithProviders);
