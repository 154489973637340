import { Badge, Link, Text, Tooltip } from '@vault';
import { TestTubeDiagonal } from 'lucide-react';
import MixedList from '../Common/MixedList/MixedList';

function SandboxIndicator() {
  return (
    <Tooltip align="start" interactive content={<SandboxTooltip />}>
      <Badge icon={<TestTubeDiagonal />}>Sandbox</Badge>
    </Tooltip>
  );
}

function SandboxTooltip() {
  return (
    <>
      <Text size="sm" weight={500} className="mb-2">
        Sandbox
      </Text>
      <Text className="mb-2" size="sm" leading="md">
        This is a sandbox. Sandbox apps allow you to evaluate Evervault
        enterprise features and test Evervault Primitives, whilst providing
        clear separation of production and non production data.
      </Text>
      <Link external href="https://docs.evervault.com/more/sandbox">
        Learn More {'->'}
      </Link>
    </>
  );
}

function SandboxInfo({ isSandbox }) {
  return (
    <MixedList.Row>
      <MixedList.Data>
        <h6>App mode</h6>
        <p>{isSandbox ? 'Sandbox' : 'Production'}</p>
      </MixedList.Data>
    </MixedList.Row>
  );
}

export const Sandbox = {
  Indicator: SandboxIndicator,
  Info: SandboxInfo,
  Tooltip: SandboxTooltip,
};
