import {
  useMutation,
  useQuery,
  useQueryClient,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { directApi, api, handleResponseOptions } from '@services/apiService';
import {
  enclaves as enclavesResource,
  enclave as enclaveResource,
  enclaveSecret as enclaveSecretResource,
  enclaveSecrets as enclaveSecretsResource,
  enclaveCerts as enclaveCertsResource,
  enclaveDeployments,
  enclaveVersions,
  enclaveVersion,
  enclaveMaintenanceWindow,
  enclaveAppCerts,
} from '@utils/resources/enclaves';
import { toast } from 'react-hot-toast';
import { isDeploying } from './utils';

export const ENCLAVE_STATE_FILTERS = {
  ACTIVE: 'active',
  DELETED: 'deleted',
};

const enclaveKeys = {
  all: (teamUuid, appUuid) => ['teams', teamUuid, 'apps', appUuid, 'enclaves'],
  filtered: (teamUuid, appUuid, filter) => [
    ...enclaveKeys.all(teamUuid, appUuid),
    filter,
  ],
  byId: (teamUuid, appUuid, enclaveUuid) => [
    ...enclaveKeys.all(teamUuid, appUuid),
    enclaveUuid,
  ],
};

export const enclavesQuery = (teamUuid, appUuid, filter) => ({
  queryKey: enclaveKeys.filtered(teamUuid, appUuid, filter),
  queryFn: async (params) =>
    await api
      .get(
        `${enclavesResource(teamUuid, appUuid)}?filter=${filter}&page=${
          params?.pageParam ?? 0
        }&pageSize=50`,
        handleResponseOptions('enclavesQuery')
      )
      .json(),
});

export function useEnclavesQuery(filter, props = {}) {
  const { teamUuid, appUuid } = useParams();

  return useQuery({
    ...enclavesQuery(teamUuid, appUuid, filter),
    onError: (err) => console.error(err),
    ...props,
  });
}

export function useInfiniteEnclavesQuery(filter, props = {}) {
  const { teamUuid, appUuid } = useParams();

  return useInfiniteQuery({
    ...enclavesQuery(teamUuid, appUuid, filter),
    getNextPageParam: (prevPage) => {
      const curPage = parseInt(prevPage.page);

      if (isNaN(curPage)) return undefined;

      if (prevPage?.enclaves?.length < 50 || prevPage?.total == 50) {
        // no more pages to be fetched
        return undefined;
      }

      return curPage + 1;
    },
    ...props,
  });
}

const enclaveQuery = (teamUuid, appUuid, enclaveUuid) => ({
  queryKey: enclaveKeys.byId(teamUuid, appUuid, enclaveUuid),
  queryFn: async () =>
    await api
      .get(
        enclaveResource(teamUuid, appUuid, enclaveUuid),
        handleResponseOptions('enclaveQuery')
      )
      .json(),
  enabled: enclaveUuid != null,
});

const deleteEnclaveEnvMutation = ({ teamUuid, appUuid }) => ({
  mutationFn: ({ enclaveUuid, secretUuid }) =>
    api.delete(
      enclaveSecretResource(teamUuid, appUuid, enclaveUuid, secretUuid),
      handleResponseOptions('enclaveQuery')
    ),
});

const getEnclaveEnvQuery = (teamUuid, appUuid, enclaveUuid) => ({
  queryKey: ['secrets', teamUuid, appUuid, enclaveUuid],
  queryFn: async () =>
    await api
      .get(
        enclaveSecretsResource(teamUuid, appUuid, enclaveUuid),
        handleResponseOptions('enclaveQuery')
      )
      .json(),
  enabled: enclaveUuid !== undefined && enclaveUuid !== null,
});

const getEnclaveAppCertsQuery = (teamUuid, appUuid) => ({
  queryKey: ['appCerts', teamUuid, appUuid],
  queryFn: async () =>
    await api
      .get(
        enclaveAppCerts(teamUuid, appUuid),
        handleResponseOptions('enclavesQuery')
      )
      .json(),
});

export function useGetEnclaveAppCertsQuery() {
  const { teamUuid, appUuid } = useParams();

  return useQuery(getEnclaveAppCertsQuery(teamUuid, appUuid));
}

const getEnclaveCertsQuery = (teamUuid, appUuid, enclaveUuid) => ({
  queryKey: ['certs', teamUuid, appUuid, enclaveUuid],
  queryFn: async () =>
    await api
      .get(
        enclaveCertsResource(teamUuid, appUuid, enclaveUuid),
        handleResponseOptions('enclavesQuery')
      )
      .json(),
  enabled: enclaveUuid !== undefined && enclaveUuid !== null,
});

export function useGetEnclaveCertsQuery(enclaveUuid) {
  const { teamUuid, appUuid } = useParams();

  return useQuery(getEnclaveCertsQuery(teamUuid, appUuid, enclaveUuid));
}

const addEnclaveEnvMutation = ({ teamUuid, appUuid }) => ({
  mutationFn: ({ enclaveUuid, secrets }) =>
    directApi.put(enclaveSecretsResource(teamUuid, appUuid, enclaveUuid), {
      ...handleResponseOptions('enclaveQuery'),
      json: { secrets },
    }),
});

export function useGetEnclaveEnvQuery(enclaveUuid) {
  const { teamUuid, appUuid } = useParams();

  return useQuery(getEnclaveEnvQuery(teamUuid, appUuid, enclaveUuid));
}

export function useDeleteEnclaveEnvMutation(enclaveUuid) {
  const { teamUuid, appUuid } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['secrets', teamUuid, appUuid, enclaveUuid];
  return useMutation({
    ...deleteEnclaveEnvMutation({ teamUuid, appUuid }),
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey,
        exact: false,
      }),
  });
}

export function useAddEnclaveEnvMutation(enclaveUuid) {
  const { teamUuid, appUuid } = useParams();
  const queryClient = useQueryClient();
  const queryKey = ['secrets', teamUuid, appUuid, enclaveUuid];
  return useMutation({
    ...addEnclaveEnvMutation({ teamUuid, appUuid }),
    onSuccess: async () =>
      queryClient.invalidateQueries({
        queryKey,
        exact: false,
      }),
    onError: async (err) => {
      const error = await err?.response?.json();
      throw error;
    },
  });
}

export function useEnclaveByIdQuery() {
  const queryClient = useQueryClient();
  const { teamUuid, appUuid, enclaveUuid } = useParams();

  return useQuery({
    ...enclaveQuery(teamUuid, appUuid, enclaveUuid),
    enabled: !!enclaveUuid,
    placeholderData: async () =>
      queryClient
        .getQueryData(enclaveKeys.all(teamUuid, appUuid))
        ?.find((enclaves) => enclaves.uuid === enclaveUuid),
  });
}

const deleteEnclaveMutation = (teamUuid, appUuid) => ({
  mutationFn: async (enclaveUuid) =>
    api
      .delete(
        enclaveResource(teamUuid, appUuid, enclaveUuid),
        handleResponseOptions('deleteEnclaveMutation')
      )
      .json(),
});

export function useDeleteEnclaveMutation() {
  const queryClient = useQueryClient();
  const { teamUuid, appUuid } = useParams();

  const handleMutationSettlement = useCallback(
    async () =>
      queryClient.invalidateQueries({
        queryKey: enclaveKeys.all(teamUuid, appUuid),
      }),
    [teamUuid, appUuid, queryClient]
  );

  return useMutation({
    ...deleteEnclaveMutation(teamUuid, appUuid),
    onSettled: handleMutationSettlement,
  });
}

export const useRestartEnclaveDeploymentMutation = () => {
  const queryClient = useQueryClient();
  const { teamUuid, appUuid, enclaveUuid } = useParams();
  const { data } = useInfiniteEnclaveVersions();
  const versions = data?.pages?.flatMap(({ versions }) => versions) ?? [];

  return useMutation({
    mutationFn: async () => {
      const [latestVersion] = versions;
      const [latestDeployment] = latestVersion?.enclaveDeployments ?? [];

      if (!latestDeployment) {
        toast.error('Enclave must be deployed before it can be restarted');
        return;
      }

      const canRestart = !!latestDeployment && !isDeploying(latestDeployment);
      if (!canRestart) {
        toast.error(
          'Enclave cannot be restarted until the current deployment has completed'
        );
        return;
      }

      return api
        .patch(
          enclaveResource(teamUuid, appUuid, enclaveUuid),
          handleResponseOptions('restartEnclaveDeploymentMutation')
        )
        .json();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: 'enclaveVersions' });
      queryClient.invalidateQueries({ queryKey: 'EnclaveVersion' });
      queryClient.invalidateQueries({
        queryKey: enclaveKeys.all(teamUuid, appUuid),
      });
    },
  });
};

export function useEnclaveMetrics(enclaveUuid, startTime) {
  const { teamUuid, appUuid } = useParams();

  return useQuery({
    queryKey: ['enclaveMetrics', teamUuid, appUuid, enclaveUuid, startTime],
    enabled: Boolean(enclaveUuid),
    refetchInterval: 1000 * 60 * 5, // 5 mins
    queryFn: async () =>
      await api
        .get(
          `teams/${teamUuid}/apps/${appUuid}/enclaves/${enclaveUuid}/metrics?startTime=${startTime}`,
          handleResponseOptions('EnclaveMetricsQuery')
        )
        .json(),
  });
}

const EnclaveVersionsQuery = (teamUuid, appUuid, enclaveUuid) => ({
  queryKey: ['enclaveVersions', teamUuid, appUuid, enclaveUuid],
  enabled: !!enclaveUuid,
  refetchInterval: 1000 * 60 * 15, // 15 mins
  async queryFn({ pageParam }) {
    return api
      .get(enclaveVersions(teamUuid, appUuid, enclaveUuid), {
        searchParams: {
          page: pageParam || 0,
          pageSize: 50,
          withDeployments: true,
        },
      })
      .json();
  },
});

export function useInfiniteEnclaveVersions() {
  const { teamUuid, appUuid, enclaveUuid } = useParams();

  return useInfiniteQuery({
    ...EnclaveVersionsQuery(teamUuid, appUuid, enclaveUuid),
    queryKey: ['infiniteEnclaveVersions', teamUuid, appUuid, enclaveUuid],

    getNextPageParam(prevPage) {
      const curPage = parseInt(prevPage.page);

      if (isNaN(curPage)) return undefined;

      if (prevPage?.versions?.length < 50 || prevPage?.total == 50) {
        // no more pages to be fetched
        return undefined;
      }

      return curPage + 1;
    },
  });
}

export function useEnclaveVersion(intVersion) {
  const { teamUuid, appUuid, enclaveUuid } = useParams();

  return useQuery({
    queryKey: ['EnclaveVersion', teamUuid, appUuid, enclaveUuid, intVersion],
    enabled: !!intVersion,
    refetchInterval: 1000 * 60 * 15, // 15 mins
    async queryFn() {
      return api
        .get(enclaveVersion(teamUuid, appUuid, enclaveUuid, intVersion))
        .json();
    },
  });
}

/**
 *
 * @returns {ReturnType<import('@tanstack/react-query').useMutation<unknown, unknown, { maintenanceWindowDayOfWeek: number, maintenanceWindowHourOfDay: number }>>}
 */
export function useUpdateEnclaveMaintenanceWindow() {
  const queryClient = useQueryClient();
  const { teamUuid, appUuid, enclaveUuid } = useParams();

  return useMutation({
    mutationFn: async ({
      maintenanceWindowDayOfWeek,
      maintenanceWindowHourOfDay,
    }) => {
      return api
        .put(enclaveMaintenanceWindow(teamUuid, appUuid, enclaveUuid), {
          json: {
            maintenanceWindowDayOfWeek,
            maintenanceWindowHourOfDay,
          },
          ...handleResponseOptions('updateEnclaveMaintenanceWindow'),
        })
        .json();
    },
    onSettled: (_, err) => {
      if (err == null) {
        queryClient.invalidateQueries({
          queryKey: enclaveKeys.byId(teamUuid, appUuid, enclaveUuid),
        });
      }
    },
  });
}

/**
 *
 * @returns {ReturnType<import('@tanstack/react-query').useMutation<unknown, unknown, { certUuids: string[] }>>}
 */
export function useUpdateLockedCertsMutation() {
  const queryClient = useQueryClient();
  const { teamUuid, appUuid, enclaveUuid } = useParams();

  return useMutation({
    mutationFn: async ({ certUuids }) => {
      return api
        .put(enclaveCertsResource(teamUuid, appUuid, enclaveUuid), {
          json: {
            certUuids,
          },
          ...handleResponseOptions('updateLockedCertsMutation'),
        })
        .json();
    },
    onSettled: (_, err) => {
      if (err == null) {
        queryClient.invalidateQueries(
          enclaveKeys.byId(teamUuid, appUuid, enclaveUuid)
        );
      }
    },
  });
}
