import time from '@utils/time';
import { useParams } from 'react-router-dom';
import { useTeamQuery } from '@queries/team';

export function useEnclaveLimits() {
  const { teamUuid } = useParams();
  const { data: team } = useTeamQuery(teamUuid, { suspense: true });

  if (!team) {
    return {
      maxInstances: 0,
      instancesInUse: 0,
      tier: 'enterprise',
      expirationDate: undefined,
      hasExpired: false,
    };
  }

  const expirationDate = team?.enclaveBetaEnds;
  const hasExpired = time(expirationDate) && time().isAfter(expirationDate);

  if (!team?.enclaveUsageLimits) {
    return {};
  }

  const { maxInstances, availableInstances, tier } = team.enclaveUsageLimits;
  const instancesInUse = maxInstances - availableInstances;

  return { expirationDate, hasExpired, maxInstances, instancesInUse, tier };
}

export const getLatestDeploymentForVersion = (version) => {
  if (!version?.enclaveDeployments?.length) {
    return null;
  }

  const sortedDeployments = version.enclaveDeployments.sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return dateB - dateA;
  });

  return sortedDeployments[0];
};

export const getLatestRegionalDeploymentForVersion = (version) => {
  const deployment = getLatestDeploymentForVersion(version);
  return deployment?.enclaveRegionalDeployments[0]; //Always created with deployments.
};

export const isDeployed = (deployment) =>
  deployment &&
  deployment.completedAt &&
  deployment.enclaveRegionalDeployments.every(
    ({ completedAt }) => !!completedAt
  );

export const isDeploying = (deployment) =>
  deployment &&
  deployment.startedAt &&
  !deployment.completedAt &&
  !deployment.timedOut;

export const deploymentStatus = (deployment) => {
  if (isDeployed(deployment)) {
    return 'deployed';
  }

  if (isDeploying(deployment)) {
    return 'deploying';
  }

  if (isTimedOut(deployment)) {
    return 'timedout';
  }

  return 'pending';
};

export const deploymentTimeStatus = (
  deployment,
  status = deploymentStatus(deployment)
) => {
  switch (status) {
    case 'deployed':
      return `Deployment finished at ${deployment.completedAt}`;
    case 'deploying':
      return `Started deployment ${time(deployment.startedAt).fromNow()}`;
    case 'timedout':
      return `Deployment timed out`;

    default:
      return 'Deployment pending';
  }
};

export const isDeleted = (state) => {
  return ['deleted', 'deleting'].includes(state);
};

// Version deployment statuses
export const isVersionBuildFailed = (version) => {
  return version.buildStatus === 'failed';
};

export const isVersionBuildInProgress = (version) => {
  return ['pending', 'building'].includes(version.buildStatus);
};

export const isVersionBuildComplete = (version) => {
  return version.buildStatus === 'ready';
};

export const isVersionDeployFailed = (version) => {
  const latestRegionalDeployment =
    getLatestRegionalDeploymentForVersion(version);
  return latestRegionalDeployment?.deployStatus === 'failed';
};

export const isVersionDeployPending = (version) => {
  const latestRegionalDeployment =
    getLatestRegionalDeploymentForVersion(version);
  return latestRegionalDeployment?.deployStatus === 'pending';
};

export const isVersionDeployInProgress = (version) => {
  const latestRegionalDeployment =
    getLatestRegionalDeploymentForVersion(version);
  return latestRegionalDeployment?.deployStatus === 'deploying';
};

export const isVersionDeployComplete = (version) => {
  const latestRegionalDeployment =
    getLatestRegionalDeploymentForVersion(version);
  return latestRegionalDeployment?.deployStatus === 'ready';
};

export const isTimedOut = (version) => {
  const latestDeployment = getLatestDeploymentForVersion(version);
  const latestRegionalDeployment =
    getLatestRegionalDeploymentForVersion(version);
  return (
    latestDeployment &&
    !latestDeployment.completedAt &&
    latestRegionalDeployment?.deployStatus !== 'failed' &&
    thirtyMinutesSinceCreated(
      latestRegionalDeployment?.createdAt ?? version?.createdAt
    )
  );
};

/**
 * @param {string?} createdAt
 * @returns {boolean}
 */
const thirtyMinutesSinceCreated = (createdAt) => {
  if (createdAt == null) {
    return false;
  }
  const timeOutTime = time(createdAt).add(30, 'minutes');
  return time().isAfter(timeOutTime);
};
