import { motion } from 'framer-motion';
import * as Primitive from '@radix-ui/react-select';
import { forwardRef } from 'react';
import css from './styles.module.css';
import connectFieldsStyles from '../ConnectedFields/styles.module.css';
import { ChevronDown, ChevronUp } from 'lucide-react';
import SelectOption from './SelectOption';
import { useFormField } from '@common/FormField';
import { SelectLabel } from './SelectLabel';

export type SelectProps = {
  error?: boolean;
  id?: string;
  name?: string;
  className?: string;
  placeholder?: string;
  prefix?: React.ReactNode;
  children: React.ReactNode;
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  value?: string;
  label?: string;
  onChange: (value: string) => void;
  inline?: boolean;
};

const Select = forwardRef<HTMLButtonElement, SelectProps>(function Select(
  {
    id,
    children,
    placeholder,
    prefix,
    error,
    size = 'md',
    value,
    onChange,
    name,
    label,
    className,
    disabled,
    inline,
  },
  ref
) {
  const formField = useFormField();
  const classes = [connectFieldsStyles.connectableField, css.select, className]
    .filter(Boolean)
    .join(' ');

  return (
    <Primitive.Root
      value={value}
      onValueChange={onChange}
      name={name}
      disabled={disabled}
    >
      <Primitive.Trigger
        ref={ref}
        id={id || formField?.id}
        className={classes}
        data-error={error || Boolean(formField?.error) || undefined}
        data-size={size}
        data-inline={inline}
        aria-label={label}
      >
        {prefix && <span className={css.prefix}>{prefix}</span>}
        <span className={css.value}>
          <Primitive.Value placeholder={placeholder || 'Select...'} />
        </span>
        <Primitive.Icon className={css.icon}>
          <ChevronDown />
        </Primitive.Icon>
      </Primitive.Trigger>

      <Primitive.Portal>
        <Primitive.Content sideOffset={8} position="popper" asChild>
          <motion.div
            data-size={size}
            className={css.menu}
            initial={{ y: -10, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
          >
            <Primitive.ScrollUpButton className={css.scrollUp}>
              <ChevronUp />
            </Primitive.ScrollUpButton>
            <Primitive.Viewport>{children}</Primitive.Viewport>
            <Primitive.ScrollDownButton className={css.scrollDown}>
              <ChevronDown />
            </Primitive.ScrollDownButton>
          </motion.div>
        </Primitive.Content>
      </Primitive.Portal>
    </Primitive.Root>
  );
});

const SelectNamespace = Object.assign(Select, {
  Option: SelectOption,
  Label: SelectLabel,
  Group: Primitive.Group,
});

export { SelectOption, SelectLabel, SelectNamespace as Select };
