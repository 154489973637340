import { EvervaultProvider } from '@evervault/react';
import { Route, Outlet, useParams, useLocation } from 'react-router-dom';

import SentryRoutes from '@routes/SentryRoutes';
import { lazyRoute } from '@utils/lazyRoute';

import FunctionsList from '@components/Functions/FunctionsList/FunctionsList';
import EnclavesList from '@components/Enclaves/EnclaveList/EnclaveList';
import { BillingBounce } from './BillingBounce';
import { useCurrentApp, useCurrentTeam } from '@queries/user';
import NotFound from '@components/NotFound/NotFound';
import { useStoredTeamAndApp } from '@routes/RedirectToFirstTeam';
import { AppPending } from './AppPending';
import Workflow from './Workflow/Workflow';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
const Relays = lazyRoute(() => import('@components/Relays'));

const Overview = lazyRoute(() => import('@components/Overview'));
const Settings = lazyRoute(() => import('@components/TeamApp/Settings'));
const Function = lazyRoute(() => import('./Function/Function'));
const DataRoles = lazyRoute(() => import('@components/DataRoles'));
const Enclaves = lazyRoute(() => import('./Enclaves/Enclaves'));
const Logs = lazyRoute(() => import('@components/Logs'));
const Compliance = lazyRoute(() => import('@components/Compliance'));

const customEvervaultConfig = {
  urls: { keysUrl: import.meta.env.VITE_EV_KEYS_URL },
};

const AppLayout = () => {
  const { app } = useCurrentApp();
  const { teamUuid, appUuid } = useParams();

  if (!app) {
    return <NotFound />;
  }

  if (app.status === 'pending') {
    return <AppPending />;
  }

  return (
    <BillingBounce>
      <EvervaultProvider
        teamId={teamUuid}
        appId={appUuid}
        customConfig={customEvervaultConfig}
      >
        <Outlet />
      </EvervaultProvider>
    </BillingBounce>
  );
};

function PageTracking() {
  const { team } = useCurrentTeam();
  const { appUuid } = useParams();
  const location = useLocation();

  useEffect(() => {
    if (window.plausible) {
      plausible('pageview', {
        props: { team: team?.name, teamId: team?.uuid, appId: appUuid },
      });
    }
  }, [team, appUuid, location.pathname]);

  return null;
}

export default function App() {
  const flags = useFlags();
  useStoredTeamAndApp();

  return (
    <>
      <PageTracking />
      <SentryRoutes>
        <Route element={<AppLayout />}>
          <Route index element={<Overview />} />

          <Route path="relays/*" element={<Relays />} />
          <Route path="functions" element={<FunctionsList />} />
          <Route path="functions/:functionName/*" element={<Function />} />
          <Route path="enclaves" element={<EnclavesList />} />
          <Route path="enclaves/:enclaveUuid/*" element={<Enclaves />} />
          <Route path="logs" element={<Logs />} />
          <Route path="policies" element={<DataRoles />} />
          <Route path="settings/*" element={<Settings />} />
          <Route path="workflows" element={<Workflow />} />
          {flags.complianceTab && (
            <Route path="compliance/*" element={<Compliance />} />
          )}
          <Route path="*" element={<NotFound />} />
        </Route>
      </SentryRoutes>
    </>
  );
}
