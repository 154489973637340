import { app } from './apps';

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const enclaves = (teamUuid, appUuid) =>
  `${app(teamUuid, appUuid)}/enclaves`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} enclaveUuid
 * @returns {string}
 */
export const enclave = (teamUuid, appUuid, enclaveUuid) =>
  `${enclaves(teamUuid, appUuid)}/${enclaveUuid}`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} enclaveUuid
 * @returns {string}
 */
export const enclaveSecrets = (teamUuid, appUuid, enclaveUuid) =>
  `${enclave(teamUuid, appUuid, enclaveUuid)}/secrets`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} enclaveUuid
 * @param {string} secretUuid
 * @returns {string}
 */
export const enclaveSecret = (teamUuid, appUuid, enclaveUuid, secretUuid) =>
  `${enclaveSecrets(teamUuid, appUuid, enclaveUuid)}/${secretUuid}`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @returns {string}
 */
export const enclaveAppCerts = (teamUuid, appUuid) =>
  `${app(teamUuid, appUuid)}/enclaves/signing/certs`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} enclaveUuid
 * @returns {string}
 */
export const enclaveCerts = (teamUuid, appUuid, enclaveUuid) =>
  `${enclave(teamUuid, appUuid, enclaveUuid)}/signing/certs`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} enclaveUuid
 * @returns {string}
 */
export const enclaveDeployments = (teamUuid, appUuid, enclaveUuid) =>
  `${enclave(teamUuid, appUuid, enclaveUuid)}/deployments`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} enclaveUuid
 * @returns {string}
 */
export const enclaveVersions = (teamUuid, appUuid, enclaveUuid) =>
  `${enclave(teamUuid, appUuid, enclaveUuid)}/versions`;

/**
 *
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} enclaveUuid
 * @returns {string}
 */
export const enclaveVersion = (
  teamUuid,
  appUuid,
  enclaveUuid,
  enclaveVersion
) => `${enclaveVersions(teamUuid, appUuid, enclaveUuid)}/${enclaveVersion}`;

/**
 * @param {string} teamUuid
 * @param {string} appUuid
 * @param {string} enclaveUuid
 * @returns {string}
 */
export const enclaveMaintenanceWindow = (teamUuid, appUuid, enclaveUuid) =>
  `${enclave(teamUuid, appUuid, enclaveUuid)}/maintenance-window`;
