interface DeferredValue<T> {
  timeout: number;
  value: Promise<T | undefined>;
  setValue: (value: T) => void;
}

export class Deferred<T> implements DeferredValue<T> {
  timeout: number;
  private _value?: T;

  constructor(timeout: number) {
    this.timeout = timeout;
  }

  setValue(value: T) {
    this._value = value;
  }

  get value(): Promise<T | undefined> {
    if (this._value != null) {
      return Promise.resolve(this._value);
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this._value);
      }, this.timeout);
    });
  }
}

interface AccessTokenStore {
  deferredLogoutHandler: Deferred<() => Promise<void>>;
  deferredGetTokenHandler: Deferred<() => Promise<string>>;
  setGetTokenHandler(handler: () => Promise<string>): void;
  setLogoutHandler(handler: () => Promise<void>): void;
  getToken(): Promise<string | undefined>;
  logout: () => Promise<void>;
  getTokenHandler?: () => Promise<string>;
  logoutHandler?: () => Promise<void>;
}

export const accessTokenStore: AccessTokenStore = {
  deferredLogoutHandler: new Deferred(50),
  deferredGetTokenHandler: new Deferred(50),

  setLogoutHandler(handler: () => Promise<void>) {
    this.deferredLogoutHandler.setValue(handler);
  },
  setGetTokenHandler(handler: () => Promise<string>) {
    this.deferredGetTokenHandler.setValue(handler);
  },
  async getToken() {
    const handler = await this.deferredGetTokenHandler.value;
    if (handler != null) {
      return handler();
    }
  },
  async logout() {
    const handler = await this.deferredLogoutHandler.value;
    if (handler) {
      return handler();
    }
  },
};

export const getAccessToken = () => accessTokenStore.getToken();
