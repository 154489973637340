import {
  ChangeEvent,
  ComponentPropsWithoutRef,
  forwardRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import css from './styles.module.css';
import connectFieldsStyles from '../ConnectedFields/styles.module.css';
import { useFormField } from '@common/FormField';

export type TextareaProps = Omit<
  ComponentPropsWithoutRef<'textarea'>,
  'size'
> & {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  error?: boolean;
  width?: 'auto';
  autoExpand?: boolean;
};

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function Textarea(
    {
      className,
      size = 'md',
      error,
      onBlur,
      onFocus,
      width,
      onChange,
      onPaste,
      autoExpand,
      ...props
    },
    externalRef
  ) {
    const internalRef = useRef<HTMLTextAreaElement>(null);
    const ref = useMemo(() => externalRef ?? internalRef, [externalRef]);
    const formField = useFormField();
    const [focused, setFocused] = useState(false);
    const classes = [
      connectFieldsStyles.connectableField,
      css.textarea,
      className,
    ]
      .filter(Boolean)
      .join(' ');

    const updateHeight = useCallback(() => {
      if (!autoExpand) return;
      const textarea = ref.current;
      if (!textarea) return;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }, [ref]);

    useLayoutEffect(updateHeight, [updateHeight]);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
      updateHeight();
      onChange?.(e);
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain');
      document.execCommand('insertText', false, text.replace(/\n/g, ' '));
      onPaste?.(e);
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      const container = e.target as HTMLDivElement;
      container.querySelector('input')?.focus();
    };

    const handleFocus = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(true);
      if (onFocus) onFocus(e);
    };

    const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(false);
      if (onBlur) onBlur(e);
    };
    return (
      <div
        className={classes}
        data-focused={focused}
        data-size={size}
        data-error={error ?? Boolean(formField?.error)}
        data-width={width}
        onClick={handleClick}
        data-disabled={props.disabled}
      >
        <textarea
          id={formField?.id ?? props.id}
          ref={ref}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onPaste={handlePaste}
          {...props}
        />
      </div>
    );
  }
);
