import React, { createContext, useContext, useEffect, useState } from 'react';
import { useUserQuery } from '@queries/user';

interface PlainContextType {
  isLoaded: boolean;
  Plain: any;
}

const PlainContext = createContext<PlainContextType | undefined>(undefined);

interface PlainProviderProps {
  children: React.ReactNode;
}

export const PlainProvider: React.FC<PlainProviderProps> = ({ children }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { data } = useUserQuery({ suspense: true });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://chat.cdn-plain.com/index.js';
    script.async = false;
    script.onload = () => {
      (window as any).Plain.init({
        appId: import.meta.env.VITE_PLAIN_APP_ID,
        style: {
          chatButtonColor: '#6633ee',
          chatButtonIconColor: '#FFFFFF',
        },
        customerDetails: {
          fullName: data?.name,
          shortName: data?.name?.split(' ')[0],
          emailHash: data?.emailHash,
          email: data?.email,
        },
        hideLauncher: true,
      });

      (window as any).Plain.show = () => {
        (window as any).Plain.open();
      };

      setIsLoaded(true);
    };
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, [data]);

  return (
    <PlainContext.Provider value={{ isLoaded, Plain: (window as any).Plain }}>
      {children}
    </PlainContext.Provider>
  );
};

export const usePlain = () => {
  const context = useContext(PlainContext);
  if (!context) {
    throw new Error('usePlain must be used within a PlainProvider');
  }
  return context;
};
