import css from './Layout.module.css';
import ModalProvider from '@contexts/ModalContext';
import AnnouncementBanner from './AnnouncementBanner/AnnouncementBanner';
import LayoutHeader from './Header';
import LayoutOutlet from './LayoutOutlet';
import LayoutBody from './LayoutBody';
import LayoutLoader from './LayoutLoader';
import { LayoutBackButton } from './LayoutBackButton';
import { PlainProvider } from '@components/Plain';

type LayoutProps = {
  children: React.ReactNode;
};

function Layout({ children }: LayoutProps) {
  return (
    <ModalProvider>
      <PlainProvider>
        <div className={css.root}>
          <AnnouncementBanner />
          {children}
        </div>
      </PlainProvider>
    </ModalProvider>
  );
}

Layout.Header = LayoutHeader;
Layout.Outlet = LayoutOutlet;
Layout.Body = LayoutBody;
Layout.Loader = LayoutLoader;
Layout.BackButton = LayoutBackButton;

export default Layout;
